import React from 'react';


const MoreEvents = () => {
  return (
    
    <section className="more-events" id="more-events">
     <a href='https://events.renvnza.in/'> <button>MORE EVENTS</button></a>
    </section>
  );
};

export default MoreEvents;
